/* External dependencies */
import { Div, Text } from 'atomize';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';
import React from 'react';

interface MotivationInfoCardProps {
  image?: IGatsbyImageData;
  imgStyle?: object;
  isBigTitleSize?: boolean;
  style?: object;
  text?: string;
  title?: string;
  videoUrl?: string;
}

export default function MotivationInfoCard({
  image,
  imgStyle,
  isBigTitleSize,
  style,
  text,
  title,
  videoUrl,
}: MotivationInfoCardProps) {
  const { t } = useI18next();

  return (
    <Div
      p={{ x: '2.75rem', t: '2.75rem' }}
      bg="#fff"
      rounded="twenty"
      {...style}
      d="flex"
      flexDir="column"
      justify="space-between"
    >
      <Div>
        <Text
          tag="h3"
          textSize={{
            xs: 'errorTitle',
            lg: isBigTitleSize ? 'title' : 'downloadAppTitle',
          }}
          m={{ b: '1.75rem', r: '1rem' }}
          textWeight="900"
        >
          <Trans>{title}</Trans>
        </Text>
        {text && (
          <Text
            textColor="rgba(13, 16, 16, 0.40)"
            textSize="display2"
            textWeight="500"
            m={{ b: { xs: '1.25rem', md: '3.5rem' } }}
          >
            <Trans>{text}</Trans>
          </Text>
        )}
      </Div>
      {image && (
        <Div {...imgStyle}>
          <GatsbyImage alt={t(title)} image={image} />
        </Div>
      )}
      {videoUrl && (
        <Div
          m={{ m: '2rem' }}
          rounded="twenty"
          overflow="hidden"
          className="youtube"
        >
          <iframe
            width="560"
            height="315"
            src={videoUrl}
            title={t(title)}
            frameBorder="0"
            allow="fullscreen"
            allowFullScreen
          ></iframe>
        </Div>
      )}
    </Div>
  );
}
