/* External dependencies */
import { Div, Text } from 'atomize';
import { Trans } from 'gatsby-plugin-react-i18next';
import React from 'react';

interface SmilesTextCardProps {
  children: string;
}

export default function SmilesTextCard({ children }: SmilesTextCardProps) {
  return (
    <Div
      bg="#fff"
      p="32px 46px"
      rounded="twenty"
      align="center"
      d="flex"
      h="100%"
    >
      <Text textSize={{ xs: 'smallSize2', lg: 'display2' }} textWeight="500">
        <Trans>{children}</Trans>
      </Text>
    </Div>
  );
}
