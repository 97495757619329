export const noMotivationdata = [
  'largePromotionCosts',
  'reducedEfficiencyMarketingChannels',
  'territorialProblems',
  'problemsOfPromotingRXDrugs',
];

export const impactOnSalesText = [
  'improvingPharmacistsKnowledgeWithVideos',
  'consolidationOfKnowledgeThroughTestsAndRepeatedViewings',
  'furtherPlansToOpenAccessToVideosForUsersAndDoctors',
];

export const partnersSteps = [
  {
    title: 'companyMakesVideosAndTests',
    text: 'weAlwaysReadyToHelpYouWithThePreparationOfMaterials',
  },
  {
    title: 'wePublishVideosAndTest',
    text: 'inMobileApplicationMecheckMeducationSection',
  },
  {
    title: 'pharmacistsWatchVideosAndTakeDrugTests',
    text: 'byPassingTestsPharmacistsEarnPoints',
  },
];
