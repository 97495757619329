/* External dependencies */
import { Col, Container, Div, Icon, Row, Text } from 'atomize';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';
import React from 'react';

/* Local dependencies */
import Footer from '@components/website/footer/Footer';
import MeducationHeader from '@entities/meducation/ui/meducation-header';
import MeducationHero from '@entities/meducation/ui/meducation-hero';
import MotivationInfoCard from './MotivationInfoCard';
import SmilesTextCard from './SmilesTextCard';
import { impactOnSalesText, noMotivationdata, partnersSteps } from './data';
import MeducationBlock from '../meducation-block';
import MeducationReviews from '../meducation-reviews';
import MeducationCompanies from '../meducation-companies';

export default function PartnersPage() {
  const images = useStaticQuery(query);
  const { t } = useI18next();
  const motivationData = [
    {
      image: getImage(images?.motivationImage1),
      style: { h: '100%', m: { b: { xs: '1.25rem' } } },
      text: 'pharmacistsCanWatchVideoWherever',
      title: 'anywhereAndEveryDay',
      imgStyle: {
        d: 'flex',
        justify: { xs: 'center', md: 'end' },
      },
    },
    {
      array: [
        {
          image: getImage(images?.motivationImage2),
          title: 'shortVideosInsteadOfLectures',
          imgStyle: { d: 'flex', justify: { xs: 'center', md: 'end' } },
          style: { m: { b: '1.25rem' } },
        },
        {
          videoId: 'NmD69KX1zKM',
          videoUrl:
            'https://www.youtube.com/embed/NmD69KX1zKM?si=XENRML20lVezJglC',
          title: 'competitionsAndEvents',
          style: { p: { x: '2.75rem', t: '2.75rem', b: '2rem' } },
        },
      ],
    },
    {
      array: [
        {
          image: getImage(images?.motivationImage3),
          title: 'pointRatingSystem',
          style: { m: { b: '1.25rem' }, p: { l: '2.75rem', t: '2.75rem' } },
          imgStyle: {
            d: 'flex',
            justify: 'end',
          },
        },
        {
          image: getImage(images?.motivationImage4),
          title: 'dailyBonusesForPharmacists',
          style: { p: { x: '2.75rem', t: '2.75rem' } },
        },
      ],
    },
    {
      image: getImage(images?.motivationImage5),
      style: { h: '100%', m: { b: { xs: '1.25rem', lg: '0' } } },
      imgStyle: {
        d: 'flex',
        justify: 'center',
      },
      title: 'exchangePointsForPrizes',
    },
  ];
  const heroImageStyle = {
    pos: { md: 'absolute' },
    bottom: { md: '-6rem', xl: '-10rem' },
    maxW: { xs: '100%', sm: '70%', md: '100%' },
    m: {
      b: { xs: '-7rem', sm: '-10rem', md: '0' },
      x: { xs: 'auto', md: '0' },
    },
  };

  return (
    <>
      <Div
        bg="linear-gradient(135deg, #00D68F 0%, #98F0D3 100%), #0AD893;"
        overflow="hidden"
      >
        <MeducationHeader headerTitle="meducation for partners" />
        <MeducationHero
          isDownloadQrCode={true}
          imgStyle={{
            pos: { md: 'absolute' },
            left: { md: '5%' },
            top: { md: '-5%' },
            w: { md: 'auto' },
            h: { md: '125%' },
            maxH: { sm: '400px', md: '125%' },
            m: { b: { xs: '-20%', sm: '-15%', md: '0' } },
            d: 'flex',
            justify: { xs: 'center', md: 'flex-start' },
          }}
        />
        <MeducationBlock
          blockBg="linear-gradient(118deg, #BDBDCE 0%, #E2E8F0 100%), linear-gradient(95deg, #887EFF 0%, #B2ABFF 99.99%, #98F0D3 100%);"
          blockPadding={{
            t: { xs: '98px', md: '84px' },
            b: { xs: '70px', md: '84px' },
          }}
          blockTitle="weCreatedAmazingAppAndTellYouWhy"
          blockTitleColor="#fff"
          blockTitleTextAlign="center"
        />
      </Div>
      <Container>
        <Div tag="section" p={{ t: '4.5rem', b: '1.25rem' }}>
          <Text
            textSize={{ xs: 'errorTitle', md: 'bigTitle' }}
            m={{ b: '2rem' }}
            textWeight="900"
          >
            <Trans>whatIsHappeningNow</Trans>
          </Text>
          <Row>
            {noMotivationdata?.map((item) => (
              <Col
                key={item}
                size={{ xs: '12', md: '6' }}
                style={{ marginBottom: '1.25rem' }}
              >
                <SmilesTextCard>{item}</SmilesTextCard>
              </Col>
            ))}
          </Row>
        </Div>
        <Div tag="section" p={{ t: '2rem', b: '4rem' }}>
          <Text
            textSize={{ xs: 'errorTitle', md: 'bigTitle' }}
            m={{ b: '2rem' }}
            textWeight="900"
          >
            <Trans>userMotivation</Trans>
          </Text>
          <Row>
            {motivationData?.map((motivation, index) => (
              <Col
                size={{ xs: '12', md: '6' }}
                key={index}
                style={{ marginBottom: '1.25rem' }}
                d={motivation?.array ? 'flex' : 'block'}
                justify={motivation?.array ? 'space-between' : 'baseline'}
                flexDir={motivation?.array && 'column'}
              >
                {motivation?.array ? (
                  motivation?.array?.map((motivationArray, indexArr) => (
                    <MotivationInfoCard
                      key={indexArr}
                      image={motivationArray?.image}
                      imgStyle={motivationArray?.imgStyle}
                      title={motivationArray?.title}
                      style={motivationArray?.style}
                      videoUrl={motivationArray?.videoUrl}
                      videoId={motivation?.videoId}
                    />
                  ))
                ) : (
                  <MotivationInfoCard
                    key={index}
                    image={motivation?.image}
                    style={motivation?.style}
                    title={motivation?.title}
                    text={motivation?.text}
                    imgStyle={motivation?.imgStyle}
                    isBigTitleSize={true}
                  />
                )}
              </Col>
            ))}
          </Row>
        </Div>
      </Container>
      <Div
        tag="section"
        p={{ t: '1.75rem', b: { xs: '1.75rem', lg: '0' } }}
        bg="linear-gradient(135deg, #00D68F 0%, #98F0D3 100%);"
      >
        <Container>
          <Row>
            <Col size={{ xs: '12', lg: '8' }}>
              <Text
                tag="h3"
                textSize={{ xs: 'errorTitle', lg: 'bigTitle' }}
                m={{ b: '1.5rem' }}
                textColor="#fff"
              >
                <Trans>impactOnSales</Trans>
              </Text>
              <Div
                bg="#fff"
                rounded="twenty"
                p={{ y: '1.5rem', x: '2rem', b: '0.5rem' }}
                m={{ b: '3rem' }}
              >
                {impactOnSalesText?.map((text, index) => (
                  <Div key={index} d="flex" align="center" m={{ b: '1.5rem' }}>
                    <Div
                      bg="#01D790"
                      rounded="circle"
                      w="30px"
                      h="30px"
                      d="flex"
                      justify="center"
                      align="center"
                      m={{ r: '1rem' }}
                    >
                      <Icon name="Checked" size="28px" color="#fff" />
                    </Div>
                    <Text textSize="display2">
                      <Trans>{text}</Trans>
                    </Text>
                  </Div>
                ))}
              </Div>
            </Col>
            <Col
              size={{ xs: '12', lg: '4' }}
              d={{ xs: 'none', lg: 'flex' }}
              align="end"
            >
              <GatsbyImage
                alt={`${t('descSeconderyHero')} - Medcheck`}
                image={getImage(images?.impactOnSales)}
              />
            </Col>
          </Row>
        </Container>
      </Div>
      <Container>
        <Div tag="section" p={{ t: '4rem', b: '2rem' }}>
          <Text
            textSize={{ xs: 'errorTitle', md: 'bigTitle' }}
            m={{ b: '2rem' }}
            textWeight="900"
          >
            <Trans>stagesOfCooperation</Trans>
          </Text>
          {partnersSteps?.map((partner, index) => (
            <Div
              key={index}
              d="flex"
              align="center"
              bg="#fff"
              rounded="twenty"
              p={{
                y: { xs: '2rem', md: '4.375rem' },
                x: { xs: '1rem', md: '4.375rem' },
              }}
              m={{ b: '1.5rem' }}
              flexDir={{ xs: 'column', sm: 'row' }}
            >
              <Div
                bg="#BFBFD0"
                rounded="circle"
                w={{ xs: '4rem', md: '7.5rem' }}
                h={{ xs: '4rem', md: '7.5rem' }}
                d="flex"
                justify="center"
                align="center"
                m={{
                  r: { xs: '0', sm: '1.5rem', md: '65px' },
                  b: { xs: '0.5rem', sm: '0' },
                }}
              >
                <Text
                  textSize={{ xs: 'title', md: 'bigTitle' }}
                  textWeight="700"
                  textColor="#fff"
                >
                  {index + 1}
                </Text>
              </Div>
              <Div
                w={{
                  xs: '100%',
                  sm: 'calc(100% - 5.5rem)',
                  md: 'calc(100% - (65px + 7.5rem))',
                }}
                textAlign={{ xs: 'center', sm: 'left' }}
              >
                <Text
                  textSize={{ xs: 'display2', md: 'downloadAppTitle' }}
                  textWeight="900"
                  m={{ b: '1rem' }}
                >
                  <Trans>{partner?.title}</Trans>
                </Text>
                <Text
                  textColor="grayTextInCard"
                  textSize={{ xs: 'smallSize1', md: 'display2' }}
                >
                  <Trans>{partner?.text}</Trans>
                </Text>
              </Div>
            </Div>
          ))}
        </Div>
      </Container>
      <MeducationReviews />
      <Div overflow="hidden">
        <MeducationCompanies linkToAsana="https://form.asana.com/?k=O-KpdyCAFyYcTxxQsSeYoA&d=1200589301037777" />
        <Div bg="linear-gradient(135deg, #00D68F 0%, #98F0D3 100%), #0AD893;">
          <MeducationHeader
            isLabel={false}
            isSwitchLocale={false}
            headerTitle="meducation for partners"
          />
          <MeducationHero
            isDownloadQrCode={true}
            style={{ d: { xs: 'none', md: 'block' } }}
            imgStyle={{
              pos: { md: 'absolute' },
              left: { md: '5%' },
              top: { md: '-5%' },
              w: { md: 'auto' },
              h: { md: '125%' },
              maxH: { sm: '400px', md: '125%' },
              m: { b: { xs: '-20%', sm: '-15%', md: '0' } },
              d: 'flex',
              justify: { xs: 'center', md: 'flex-start' },
            }}
          />
        </Div>
        <Footer />
      </Div>
    </>
  );
}

export const query = graphql`
  query {
    motivationImage1: file(relativePath: { eq: "247-anywhere.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    motivationImage2: file(relativePath: { eq: "short-videos.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    motivationImage3: file(relativePath: { eq: "motivation-image-3.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    motivationImage4: file(relativePath: { eq: "motivation-image-4.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    motivationImage5: file(relativePath: { eq: "exchange-points.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    impactOnSales: file(relativePath: { eq: "impact-on-sales.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
